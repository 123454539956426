import head from '../../nanoutils/src/head';
import last from '../../nanoutils/src/last';

const SECS_IN_HOUR = 3600; // 60 * 60
const SECS_IN_MIN = 60;
const SECS_IN_SEC = 1;

export function decodeVtt(data: string) {
  const array = data.split(/\n\s*\n+/gm);

  if (!array.length) return [];

  const format = array.shift()?.trim();

  if (format !== 'WEBVTT') {
    return [];
  }

  const timeDelim = '-->';

  return array
    .map((str, idx) => {
      const lines = str.split(/\n/g);

      const period = lines.shift()?.split(timeDelim);

      if (!period) return;

      const h = head(period);
      const l = last(period);

      if (!h || !l) return;

      const from = timeCodeToSeconds(h.trim());
      const to = timeCodeToSeconds(l.trim());

      const text = lines[0];

      return { from, to, text, id: idx };
    })
    .filter((t) => !!t);
}

function timeCodeToSeconds(time: string): number {
  // 00:00:00 HH:MM:SS
  // 00:00    MM:SS
  // 00       SS
  const parts = time.split(':');
  const multiples = [SECS_IN_HOUR, SECS_IN_MIN, SECS_IN_SEC];
  let seconds = 0;

  while (parts.length && multiples.length) {
    seconds += Number(parts.pop()) * Number(multiples.pop());
  }

  return seconds;
}
