import curry2, { CurriedFunction1 } from './curry2';

export type Comparator<T> = (item: T) => number | string;

interface SortBy {
  <T>(iteratees: Comparator<T>): CurriedFunction1<T[], T[]>;
  <T>(iteratees: Comparator<T>, arr: T[]): T[];
}

const sortBy: SortBy = curry2(_sortBy);
export default sortBy;

function _sortBy<T>(iteratees: Comparator<T>, arr: T[]): T[] {
  const clone = [...arr];

  return clone.sort((a, b) => {
    const v1 = iteratees(a);
    const v2 = iteratees(b);

    return v1 > v2 ? 1 : v1 < v2 ? -1 : 0;
  });
}

export function desc<T>(iteratees: Comparator<T>): Comparator<T> {
  return function it(e: T) {
    const val = iteratees(e);
    return typeof val === 'number' ? -1 * val : val;
  };
}
