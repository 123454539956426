import $t from '@ravnur/l10n/$t';
import { defineComponent } from 'vue';

import './messages.scss';

const CN = 'messages';
const TEXT_CN = `${CN}__text`;

export default defineComponent({
  props: {
    value: { type: String },
  },
  setup(props) {
    const messages = (props.value || $t('common', 'filter-list__empty')).split('\n');

    return () => (
      <div class={CN}>
        {messages.map((text) => (
          <p key={text} class={TEXT_CN}>
            {text}
          </p>
        ))}
      </div>
    );
  },
});
